.loginSection {
  .signupFormWrap {
    text-align: center;
    padding-bottom: 2em;

    input {
      width: 100%;
      padding: 0.5em;
      margin: 0 0 2em 0;
      font-size: 1.4em;
    }

    p {
      color: #000;
      font-size: 1.4em;
      text-decoration: underline;
      text-underline-position: under;
    }

    .forgotPasswordWrap {
      position: relative;
      bottom: 3.8em;
      font-size: 0.6em;
      text-align: right;
    }
  }
}